import Simple from '@/components/task-templates/Simple'
import CodeTest from '@/components/task-templates/CodeTest'

// функции-генераторы

export default [
  {
    component: CodeTest,
    lang: 'py',
    name: 'Умножение на 2',
    text: 'Напишите функцию \\(mul2\\), которая принимает число \\(a\\) в качестве параметра, и возвращает это число, умноженное на 2',
    note: null,
    examples: [
      {
        input: `
          print(mul2(1))
          print(mul2(3))
        `,
        output: `
          2
          6
        `
      }
    ]
  },
  {
    component: Simple,
    name: 'Вызов mul2',
    text: 'Дано число \\(a\\). Выведите \\(4 * a\\) и \\(6 * a\\), используя функцию \\(mul2\\). Допускается одно сложение',
    note: null,
    input: '\\(a\\) - целое',
    output: 'Два числа через пробел',
    examples: [
      {
        input: '2',
        output: '8 12'
      },
      {
        input: '-1',
        output: '-4 -6'
      },
      {
        input: '5',
        output: '20 30'
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'py',
    name: 'Сумма двух чисел',
    text: 'Напишите функцию \\(sum\\_pair\\), которая принимает числа \\(a\\) и \\(b\\), возвращает их сумму',
    note: null,
    examples: [
      {
        input: `
          print(sum_pair(1, 2))
          print(sum_pair(5, -3))
        `,
        output: `
          3
          2
        `
      }
    ]
  },
  {
    component: Simple,
    name: 'Вызов sum_pair',
    text: 'Даны числа \\(a\\) и \\(b\\). Используя функцию \\(sum\\_pair\\), выведите \\(2 * (a + b)\\), не используя умножение, и разность \\(a\\) и \\(b\\), не используя вычитание',
    note: null,
    input: '\\(a\\) и \\(b\\) - целые',
    output: 'Два числа через пробел',
    examples: [
      {
        input: '5, 3',
        output: '16 2'
      },
      {
        input: '10, 20',
        output: '60 -10'
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'py',
    name: 'Нечётность',
    text: 'Напишите функцию \\(is\\_even\\), которая принимает число \\(a\\) и возвращает True, если оно чётное, либо False, если нечётное',
    note: null,
    examples: [
      {
        input: `
          print(is_even(5))
          print(is_even(6))
        `,
        output: `
          False
          True
        `
      }
    ]
  },
  {
    component: Simple,
    name: 'Вызов is_even',
    text: 'Даны числа \\(a\\), \\(b\\) и \\(c\\). Используя функцию \\(is\\_even\\), выведите, являются ли \\(a\\), \\(b\\) и \\(c\\) одновременно чётными. Затем, есть ли среди них хотя бы одно нечётное. Затем, одинаковая ли чётность у всех чисел',
    note: null,
    input: '\\(a\\), \\(b\\) и \\(c\\) - целые',
    output: 'True и False по одному на строку',
    examples: [
      {
        input: '5, 3, 7',
        output: 'False\nTrue\nTrue'
      },
      {
        input: '4, 2, 6',
        output: 'True\nFalse\nTrue'
      },
      {
        input: '5, 1, 2',
        output: 'False\nTrue\nFalse'
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'py',
    name: 'Оканчивается на 4 или 7',
    text: 'Напишите функцию \\(ends\\_with\\_47\\), которая принимает целое число \\(a\\) (\\(a \\ge 0\\)) и возвращает True, если оно оканчивается на 4 или 7, в противном случае False',
    note: null,
    examples: [
      {
        input: `
          print(ends_with_47(7))
          print(ends_with_47(14))
          print(ends_with_47(21))
          print(ends_with_47(70))
          print(ends_with_47(27))
          print(ends_with_47(304))
        `,
        output: `
          True
          True
          False
          False
          True
          True
        `
      }
    ]
  },
  {
    component: Simple,
    name: 'Вызов ends_with_47',
    text: 'Даны числа \\(a\\), \\(b\\) и \\(c\\). Используя функцию \\(ends\\_with\\_47\\), выведите, сколько из чисел \\(a\\), \\(b\\) и \\(c\\) заканчиваются на 4 или 7. Затем, верно ли, что \\(a\\) и \\(b\\) заканчиваются на 4 или 7, а \\(c\\) - нет',
    note: null,
    input: '\\(a\\), \\(b\\) и \\(c\\) - целые неотрицательные',
    output: 'Число в первой строке, True или False во второй',
    examples: [
      {
        input: '5, 3, 7',
        output: '1\nFalse'
      },
      {
        input: '14, 17, 5',
        output: '2\nTrue'
      },
      {
        input: '4, 804, 77',
        output: '3\nFalse'
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'py',
    name: 'Модуль',
    text: 'Напишите функцию \\(my\\_abs\\), которая принимает целое число \\(a\\) и возвращает модуль этого числа (убирает минус, если он есть)',
    note: null,
    examples: [
      {
        input: `
          print(my_abs(10))
          print(my_abs(-14))
          print(my_abs(-0))
          print(my_abs(0))
        `,
        output: `
          10
          14
          0
          0
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'py',
    name: 'Знак',
    text: 'Напишите функцию \\(sign\\), которая принимает целое число \\(a\\) и возвращает -1, если оно отрицательное, 0, если равно нулю, и 1, если положительное',
    note: 'Не использовать else',
    examples: [
      {
        input: `
          print(sign(10))
          print(sign(-14))
          print(sign(-0))
          print(sign(0))
        `,
        output: `
          1
          -1
          0
          0
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'py',
    name: 'Равенство массивов',
    text: 'Напишите функцию \\(arrays\\_equal\\), которая принимает два целочисленных массива и возвращает True, если они одинаковые, и False, если разные',
    note: 'Решить, не используя прямое сравнение массивов',
    examples: [
      {
        input: `
          print(arrays_equal([1, 2, 3], [1, 2, 3]))
          print(arrays_equal([3, 2, 1], [1, 2, 3]))
          print(arrays_equal([1, 2, 3], [1, 2]))
          print(arrays_equal([1, 2], [1, 2, 3]))
        `,
        output: `
          True
          False
          False
          False
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'py',
    name: 'Префикс или нет?',
    text: 'Напишите функцию \\(starts\\_with\\), которая принимает два целочисленных массива и возвращает True, если первый начинается со второго и содержит все его элементы, False в противном случае',
    note: null,
    examples: [
      {
        input: `
          print(starts_with([1, 2, 3], [1, 2, 3]))
          print(starts_with([1, 2, 3], [1, 2]))
          print(starts_with([1, 2, 3], []))
          print(starts_with([1, 2, 3], [2, 3]))
          print(starts_with([1, 2, 3], [1, 3]))
          print(starts_with([1, 2, 3], [1, 2, 3, 4]))
        `,
        output: `
          True
          True
          True
          False
          False
          False
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'py',
    name: 'Суффикс или нет?',
    text: 'Напишите функцию \\(ends\\_with\\), которая принимает два целочисленных массива и возвращает True, если хвост первого совпадает со вторым массивом и содержит все его элементы, False в противном случае',
    note: null,
    examples: [
      {
        input: `
          print(ends_with([1, 2, 3], [1, 2, 3]))
          print(ends_with([1, 2, 3], [1, 2]))
          print(ends_with([1, 2, 3], []))
          print(ends_with([1, 2, 3], [2, 3]))
          print(ends_with([1, 2, 3], [1, 3]))
          print(ends_with([1, 2, 3], [1, 2, 3, 4]))
        `,
        output: `
          True
          False
          True
          True
          False
          False
        `
      }
    ]
  },
  {
    component: Simple,
    name: 'Spread в аргументах',
    text: 'Дан массив \\(a\\), состоящий из двух чисел. Используя функцию \\(sum\\_pair\\) выведите сумму элементов этого массива. Для передачи аргументов в \\(sum\\_pair\\) использовать оператор spread',
    note: null,
    input: '\\(a\\) - массив из двух целых чисел',
    output: 'Сумма',
    examples: [
      {
        input: '[1, 2]',
        output: '3'
      },
      {
        input: '[3, 4]',
        output: '7'
      }
    ]
  },
  {
    component: Simple,
    name: 'Цикл вызовов со spread в аргументах',
    text: 'Дан массив \\(a\\), состоящий из пар чисел. Каждая пара - массив длины 2. Используя функцию \\(sum\\_pair\\) выведите сумму каждой пары. Для передачи аргументов в \\(sum\\_pair\\) использовать оператор spread',
    note: null,
    input: '\\(a\\) - целочисленная матрица ?x2',
    output: 'Каждое число на новой строке',
    examples: [
      {
        input: '[[1, 2]]',
        output: '3'
      },
      {
        input: '[[3, 4], [5, 6]]',
        output: '7\n11'
      },
      {
        input: '[[7, 6], [5, 4]], [3, 2]]',
        output: '13\n9\n5'
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'py',
    name: 'Rest в параметрах - my_sum',
    text: 'Напишите функцию \\(my\\_sum\\), которая принимает неизвестное количество параметров-чисел и возвращает их сумму',
    note: null,
    examples: [
      {
        input: `
          print(my_sum())
          print(my_sum(1))
          print(my_sum(1, 2))
          print(my_sum(2, 1, 3))
        `,
        output: `
          0
          1
          3
          6
        `
      }
    ]
  },
  {
    component: Simple,
    name: 'Вызов my_sum',
    text: 'Дан целочисленный массив \\(a\\). С помощью функции \\(my\\_sum\\) выведите его сумму',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Сумма',
    examples: [
      {
        input: '[4, 2, 5]',
        output: '11'
      },
      {
        input: '[7, 2, 3]',
        output: '12'
      },
      {
        input: '[-1]',
        output: '-1'
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'py',
    name: 'Rest в параметрах - my_max',
    text: 'Напишите функцию \\(my\\_max\\), которая принимает неизвестное количество параметров-чисел и возвращает самое большое из них',
    note: 'Проще всего разложить параметры на первый и остальные',
    examples: [
      {
        input: `
          print(my_max(1))
          print(my_max(1, 2))
          print(my_max(2, 1))
          print(my_max(2, 1, 3))
          print(my_max(2, 3, 1))
          print(my_max(-2, -3, -1))
        `,
        output: `
          1
          2
          2
          3
          3
          -1
        `
      }
    ]
  },
  {
    component: Simple,
    name: 'Вызов my_max',
    text: 'Дан целочисленный массив \\(a\\). С помощью функции \\(my\\_max\\) выведите самое большое число в нём',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Максимум',
    examples: [
      {
        input: '[4, 2, 5]',
        output: '5'
      },
      {
        input: '[7, 2, 3]',
        output: '7'
      },
      {
        input: '[-1]',
        output: '-1'
      }
    ]
  },
  {
    component: Simple,
    name: 'Передача функции в параметры + массив',
    text: 'Дана функция \\(f\\), а также массив её аргументов \\(args\\). Выведите результат вызова функции, передав ей аргументы из массива',
    note: null,
    input: '\\(f\\) - функция, \\(args\\) - массив',
    output: 'Результат вызова функции',
    examples: [
      {
        input: 'mul2, [7]',
        output: '14'
      },
      {
        input: 'sum_pair, [4, 3]',
        output: '7'
      },
      {
        input: 'is_even, [3]',
        output: 'False'
      },
      {
        input: 'ends_with_47, [74]',
        output: 'True'
      },
      {
        input: 'my_abs, [-22]',
        output: '22'
      },
      {
        input: 'sign, [-22]',
        output: '-1'
      },
      {
        input: 'starts_with, [[1, 2, 3], [1, 2]]',
        output: 'True'
      },
      {
        input: 'ends_with, [[1, 2, 3], [1, 2]]',
        output: 'False'
      },
      {
        input: 'my_sum, [1, 2, 3]',
        output: '6'
      },
      {
        input: 'my_max, [3, 2, 1]',
        output: '3'
      }
    ]
  },
  {
    component: Simple,
    name: 'Передача функции в параметры + аргументы',
    text: 'Дана функция \\(f\\), а также все её аргументы \\(args\\). Выведите результат вызова функции, передав ей все аргументы',
    note: null,
    input: '\\(f\\) - функция, \\(args\\) - все аргументы',
    output: 'Результат вызова функции',
    examples: [
      {
        input: 'mul2, 7',
        output: '14'
      },
      {
        input: 'sum_pair, 4, 3',
        output: '7'
      },
      {
        input: 'is_even, 3',
        output: 'False'
      },
      {
        input: 'ends_with_47, 74',
        output: 'True'
      },
      {
        input: 'my_abs, -22',
        output: '22'
      },
      {
        input: 'sign, -22',
        output: '-1'
      },
      {
        input: 'starts_with, [1, 2, 3], [1, 2]',
        output: 'True'
      },
      {
        input: 'ends_with, [1, 2, 3], [1, 2]',
        output: 'False'
      },
      {
        input: 'my_sum, 1, 2, 3',
        output: '6'
      },
      {
        input: 'my_max, 3, 2, 1',
        output: '3'
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'py',
    name: 'for_each',
    text: 'Напишите функцию \\(for\\_each\\), которая принимает целочисленный массив \\(a\\) и функцию \\(f\\). Функция \\(f\\) принимает одно число и что-то делает, ничего не возвращает. Функция \\(for\\_each\\) должна вызывать функцию \\(f\\) на каждом элементе \\(a\\)',
    note: null,
    examples: [
      {
        input: `
          for_each([4, 2, 1], lambda n: print(n * 2))
          print('---')
          s = 0
          for_each([4, 2, 1], lambda n: s += n)
          print(s)
          print('---')
          for_each([10, 20], print)
        `,
        output: `
          8
          4
          2
          ---
          7
          ---
          10
          20
        `
      }
    ]
  },
  {
    component: Simple,
    name: 'Вызов for_each',
    text: 'Дан целочисленный массив \\(a\\). С помощью функций \\(for\\_each\\) и \\(ends\\_with\\_47\\) посчитайте, сколько чисел из этого массива заканчиваются на 4 или 7',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Количество чисел *4/*7',
    examples: [
      {
        input: '[4, 2, 5]',
        output: '1'
      },
      {
        input: '[7, 14, 27]',
        output: '3'
      },
      {
        input: '[17, 71, 41, 14]',
        output: '2'
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'py',
    name: 'find',
    text: 'Напишите функцию \\(find\\), которая принимает целочисленный массив \\(a\\) и функцию \\(f\\). Функция \\(f\\) принимает одно число и возвращает boolean. Функция \\(find\\) должна вернуть первое число из массива \\(a\\), для которого функция \\(f\\) вернёт True. Если такого числа нет, вернуть None',
    note: null,
    examples: [
      {
        input: `
          print(find([3, 5, 6, 7], is_even))
          print(find([22, 34, 37, 14], ends_with_47))
          print(find([1, 2, 3], lambda x: x < 0))
        `,
        output: `
          6
          34
          None
        `
      }
    ]
  },
  {
    component: Simple,
    name: 'Вызов find',
    text: 'Дан целочисленный массив \\(a\\). С помощью функций \\(find\\) выведите первое число, делящееся на 3, а затем на следующей строке первое число, которое больше 10',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Первое кратное 3 и первое, большее 10',
    examples: [
      {
        input: '[4, 2, 5]',
        output: 'None\nNone'
      },
      {
        input: '[2, 9, 27]',
        output: '9\n27'
      },
      {
        input: '[17, 5, 6, 3]',
        output: '6\n17'
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'py',
    name: 'find_index',
    text: 'Напишите функцию \\(find\\_index\\), которая принимает целочисленный массив \\(a\\) и функцию \\(f\\). Функция \\(f\\) принимает одно число и возвращает boolean. Функция \\(find\\_index\\) должна вернуть индекс первого числа из массива \\(a\\), для которого функция \\(f\\) вернёт True. Если такого числа нет, вернуть -1',
    note: null,
    examples: [
      {
        input: `
          print(find_index([3, 5, 6, 7], is_even))
          print(find_index([22, 34, 37, 14], ends_with_47))
          print(find_index([1, 2, 3], lambda x: x < 0))
        `,
        output: `
          2
          1
          -1
        `
      }
    ]
  },
  {
    component: Simple,
    name: 'Вызов find_index',
    text: 'Дан целочисленный массив \\(a\\). С помощью функций \\(find\\_index\\) выведите индекс первого числа, делящегося на 3, а затем на следующей строке выведите индекс первого числа, которое больше 10',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Первое кратное 3 и первое, большее 10',
    examples: [
      {
        input: '[4, 2, 5]',
        output: '-1\n-1'
      },
      {
        input: '[2, 9, 27]',
        output: '1\n2'
      },
      {
        input: '[17, 5, 6, 3]',
        output: '2\n0'
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'py',
    name: 'my_map',
    text: 'Напишите функцию \\(my\\_map\\), которая принимает целочисленный массив \\(a\\) и функцию \\(f\\). Функция \\(f\\) принимает одно число и что-то возвращает. Функция \\(my\\_map\\) должна вызывать функцию \\(f\\) на каждом элементе \\(a\\), и из результатов составить новый массив',
    note: null,
    examples: [
      {
        input: `
          print(my_map([4, 2, 1], mul2))
          print(my_map([4, 2, 9], is_even))
          print(my_map([4, 2, 9], lambda x: x % 2))
        `,
        output: `
          [8, 4, 2]
          [True, True, False]
          [0, 0, 1]
        `
      }
    ]
  },
  {
    component: Simple,
    name: 'Вызов my_map',
    text: 'Дан целочисленный массив \\(a\\). С помощью функции \\(my\\_map\\) выведите массив, в котором каждый элемент \\(a\\) будет неотрицательным (используя модуль). Затем, во второй строке ещё один массив, в котором будет накопительная сумма элементов \\(a\\) (первый элемент совпадает, а каждый следующий - сумма предыдущих + следующий элемент из \\(a\\))',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Массив с модулями и массив с накопительной суммой',
    examples: [
      {
        input: '[1, 2, -3]',
        output: '[1, 2, 3]\n[1, 3, 0]'
      },
      {
        input: '[-1, 2, -3]',
        output: '[1, 2, 3]\n[-1, 1, -2]'
      },
      {
        input: '[1, -2, 3, -4]',
        output: '[1, 2, 3, 4]\n[1, -1, 2, -2]'
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'py',
    name: 'count',
    text: 'Напишите функцию \\(count\\), которая принимает целочисленный массив \\(a\\) и функцию \\(f\\). Функция \\(f\\) принимает одно число и возвращает boolean. Функция \\(count\\) должна вернуть количество элементов массива \\(a\\), для которых функция \\(f\\) вернёт True',
    note: null,
    examples: [
      {
        input: `
          print(count([10], lambda: True))
          print(count([4, 2, 1], is_even))
          print(count([4, 2, 9, 74], ends_with_47))
          print(count([-1, 2, -3, 5, 1], lambda x: x > 0))
        `,
        output: `
          1
          2
          2
          3
        `
      }
    ]
  },
  {
    component: Simple,
    name: 'Вызов count',
    text: 'Дан целочисленный массив \\(a\\). С помощью функции \\(count\\) выведите количество чётных элементов \\(a\\), а затем на следующей строке количество делящихся на 2 или 3',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Количество чётных и количество делящихся на 2 или 3',
    examples: [
      {
        input: '[2, 4, 3, 5]',
        output: '2\n3'
      },
      {
        input: '[6, 8, 9, 10, 11, 12]',
        output: '4\n5'
      },
      {
        input: '[3, 9, 27, 81]',
        output: '0\n4'
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'py',
    name: 'my_filter',
    text: 'Напишите функцию \\(my\\_filter\\), которая принимает целочисленный массив \\(a\\) и функцию \\(f\\). Функция \\(f\\) принимает одно число и возвращает boolean. Функция \\(my\\_filter\\) должна создать новый массив, в котором будут только те элементы \\(a\\), для которых функция \\(f\\) вернёт True',
    note: 'Расширение массива в задаче не допускается. Желательно не вызывать функцию \\(f\\) на одном элементе несколько раз',
    examples: [
      {
        input: `
          print(my_filter([4, 2, 1], is_even))
          print(my_filter([4, 2, 9, 74], ends_with_47))
          print(my_filter([-1, 2, -3, 5, 1], lambda x: x > 0))
        `,
        output: `
          [4, 2]
          [4, 74]
          [2, 5, 1]
        `
      }
    ]
  },
  {
    component: Simple,
    name: 'Вызов my_filter',
    text: 'Дан целочисленный массив \\(a\\). С помощью функции \\(my\\_filter\\) выведите массив, в котором будут содержаться только нечётные элементы \\(a\\). Затем ещё один массив, в котором будет только каждый второй элемент из \\(a\\)',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Массив с нечётными элементами и массив с каждым вторым элементом \\(a\\)',
    examples: [
      {
        input: '[2, 4, 3, 5]',
        output: '[3, 5], [4, 5]'
      },
      {
        input: '[1, 2, 3, 3, 2, 1]',
        output: '[1, 3, 3, 1], [2, 3, 1]'
      },
      {
        input: '[2, 4, 8, 16, 32]',
        output: '[], [4, 16]'
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'py',
    name: 'reduce',
    text: 'Напишите функцию \\(reduce\\), которая принимает целочисленный массив \\(a\\) и функцию \\(f\\). Функция \\(f\\) принимает два числа и возвращает число. Функция \\(reduce\\) должна последовательно применить функцию \\(f\\), передавая первым аргументом результат предыдущего шага и вторым аргументом следующее число из \\(a\\). На первом шаге за результат предыдущего шага взять первый элемент из \\(a\\). Вернуть последнее полученное значение',
    note: 'Например, если взять массив [1, 2, 5] и функцию \\(sum\\_pair\\), на первом шаге вызывается sum_pair(1, 2), получается 3, на втором шаге вызывается sum_pair(3, 5), получается 8, больше элементов нет, 8 нужно вернуть',
    examples: [
      {
        input: `
          print(reduce([1, 2, 5], sum_pair))
          print(reduce([2, 3, 2], lambda a, b: a * b))
          print(reduce([3, 5, 2, 4], lambda a, b: a if a > b else b))
        `,
        output: `
          8
          12
          5
        `
      }
    ]
  },
  {
    component: Simple,
    name: 'Вызов reduce',
    text: 'Дан целочисленный массив \\(a\\). С помощью функции \\(reduce\\) выведите наименьший элемент из \\(a\\), затем сумму каждого второго элемента, начиная с первого, на следующей строке',
    note: null,
    input: '\\(a\\) - массив из целых чисел',
    output: 'Минимум и сумма элементов на нечётных позициях',
    examples: [
      {
        input: '[2, 4, 3, 5]',
        output: '2\n5'
      },
      {
        input: '[4, 7, 3, 3, 5, 9]',
        output: '3\n12'
      },
      {
        input: '[13, 8, 7, 17, 22]',
        output: '7\n42'
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'py',
    name: 'Замыкание на примере счётчика',
    text: 'Напишите функцию \\(create\\_counter\\), которая ничего не принимает, создаёт внутри себя переменную \\(count\\) со значением 0, затем возвращает новую функцию, которая тоже ничего не принимает, увеличивает значение \\(count\\) на 1, и возвращает \\(count\\)',
    note: null,
    examples: [
      {
        input: `
          f1 = create_counter()
          f2 = create_counter()
          print(f1())
          print(f1())
          print(f2())
          print(f2())
          print(f1(), f2())
        `,
        output: `
          1
          2
          1
          2
          3 3
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'py',
    name: 'Замыкание на примере сумматора',
    text: 'Напишите функцию \\(create\\_summator\\), которая ничего не принимает, создаёт внутри себя переменную \\(s\\) со значением 0, затем возвращает новую функцию, которая принимает число, увеличивает значение \\(s\\) на это число, и возвращает \\(s\\)',
    note: null,
    examples: [
      {
        input: `
          f1 = create_summator()
          f2 = create_summator()
          print(f1(4))
          print(f1(6))
          print(f2(2))
          print(f2(9))
          print(f1(3), f2(7))
        `,
        output: `
          4
          10
          2
          11
          13 18
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'py',
    name: 'Замыкание на примере частичного применения',
    text: 'Напишите функцию \\(partial\\), которая принимает произвольную функцию \\(f\\), плюс какое-то количество дополнительных параметров \\(args\\). Функция \\(partial\\) должна вернуть новую функцию, которая принимает второй набор параметров \\(args2\\), вызывает \\(f\\), передав в неё все аргументы из \\(args\\), затем все аргументы из \\(args2\\)',
    note: null,
    examples: [
      {
        input: `
          sum2 = partial(sum_pair, 2)
          print(sum2(4))
          print(sum2(7))
          max1 = partial(my_max, 4, 2, 5)
          max2 = partial(max1, 1, 3)
          print(max2(3, 1))
          print(max2(3, 6))
        `,
        output: `
          6
          9
          5
          6
        `
      }
    ]
  },
  {
    component: Simple,
    name: 'Параметры по умолчанию',
    text: 'Дано одно или два числа \\(a\\) и \\(b\\). Если второе не указано, считать его за 2. Вывести произведение чисел',
    note: null,
    input: '\\(a\\), \\(b\\) - целые числа',
    output: 'Произведение',
    examples: [
      {
        input: '3, 4',
        output: '12'
      },
      {
        input: '4',
        output: '8'
      },
      {
        input: '0',
        output: '0'
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'py',
    name: 'Пример рекурсии: факториал',
    text: 'Напишите функцию \\(factorial\\), которая принимает натуральное число \\(n\\) и возвращает произведение всех чисел от 1 до \\(n\\) включительно с помощью рекурсии',
    note: null,
    examples: [
      {
        input: `
          print(factorial(1))
          print(factorial(2))
          print(factorial(3))
          print(factorial(4))
          print(factorial(5))
          print(factorial(6))
        `,
        output: `
          1
          2
          6
          24
          120
          720
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'py',
    name: 'Пример рекурсии: поиск элемента в массиве',
    text: 'Напишите функцию \\(includes\\_recursive\\), которая принимает целочисленный массив \\(a\\) и целое число \\(n\\), возвращает True, если \\(n\\) присутствует в массиве и False в противном случае',
    note: null,
    examples: [
      {
        input: `
          print(includes_recursive([5, 2, 3, 1], 3))
          print(includes_recursive([5, 2, 3, 1], 4))
        `,
        output: `
          True
          False
        `
      }
    ]
  }
]
